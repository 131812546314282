import React from 'react';
import { styled } from '@compiled/react';
import AppSwitcherIcon from '@atlaskit/icon/core/app-switcher';
import AppsIcon from '@atlaskit/icon/core/apps';
import CreditCardIcon from '@atlaskit/icon/core/credit-card';
import IssuesIcon from '@atlaskit/icon/core/issues';
import LinkExternalIcon from '@atlaskit/icon/core/link-external';
import ProjectIcon from '@atlaskit/icon/core/project';
import ScreenIcon from '@atlaskit/icon/core/screen';
import TeamsIcon from '@atlaskit/icon/core/teams';
import LegacyActivityIcon from '@atlaskit/icon/glyph/activity';
import LegacyAddonIcon from '@atlaskit/icon/glyph/addon';
import LegacyBillingIcon from '@atlaskit/icon/glyph/billing';
import LegacyFolderIcon from '@atlaskit/icon/glyph/folder';
import LegacyIssuesIcon from '@atlaskit/icon/glyph/issues';
import LegacyPeopleGroupIcon from '@atlaskit/icon/glyph/people-group';
import LegacyScreenIcon from '@atlaskit/icon/glyph/screen';
import LegacyShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { Box, Flex, xcss, Text } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { isFedRamp } from '@atlassian/atl-context';
import { FilledLozenge } from '@atlassian/jira-command-palette/src/common/ui/custom-lozenge/index.tsx';
import { isMacOs } from '@atlassian/jira-common-components-keyboard-shortcuts-dialog/src/utils.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { NavigationItem } from '@atlassian/jira-navigation-types/src/types.tsx';
import { getEmceeDiscoverLink } from '@atlassian/jira-platform-marketplace-url-resolver/src/index.tsx';
import { manageAppsRoute } from '@atlassian/jira-router-routes-legacy-global-settings-apps-routes/src/manageAppsRoute.tsx';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ApplicationPermissions } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import {
	ADMIN_SETTINGS_ITEM_IDS,
	JIRA_SETTINGS_SECTION_IDS,
	SITE_ADMIN_SETTINGS_ITEM_HREFS,
	SITE_ADMIN_SETTINGS_ITEM_IDS,
} from './constants.tsx';
import messages from './messages.tsx';

const getApplicationsMenuLink = (appPermissions: ApplicationPermissions): string => {
	if (appPermissions.hasSoftwareAccess)
		return '/jira/settings/products/jira-software-configuration';
	if (appPermissions.hasServiceDeskAccess)
		return '/jira/settings/products/jira-service-management-configuration';

	return '/plugins/servlet/applinks/listApplicationLinks';
};

export const renderScreenIcon = () => (
	<IconWrapper>
		{}
		<LegacyScreenIcon label="" size="small" primaryColor={token('color.icon.inverse', '#FFFFFF')} />
	</IconWrapper>
);

export const renderActivityIcon = () => (
	<IconWrapper>
		{}
		<LegacyActivityIcon
			label=""
			size="small"
			primaryColor={token('color.icon.inverse', '#FFFFFF')}
		/>
	</IconWrapper>
);

export const renderFolderIcon = () => (
	<IconWrapper>
		{}
		<LegacyFolderIcon label="" size="small" primaryColor={token('color.icon.inverse', '#FFFFFF')} />
	</IconWrapper>
);

export const renderIssuesIcon = () => (
	<IconWrapper>
		{}
		<LegacyIssuesIcon label="" size="small" primaryColor={token('color.icon.inverse', '#FFFFFF')} />
	</IconWrapper>
);

export const renderAddonIcon = () => (
	<IconWrapper>
		{}
		<LegacyAddonIcon label="" size="small" primaryColor={token('color.icon.inverse', '#FFFFFF')} />
	</IconWrapper>
);

export const renderPeopleGroupIcon = () => (
	<IconWrapper dark>
		{}
		<LegacyPeopleGroupIcon
			label=""
			size="small"
			primaryColor={token('color.icon.inverse', '#FFFFFF')}
		/>
	</IconWrapper>
);

export const renderBillingIcon = () => (
	<IconWrapper dark>
		<CreditCardIcon
			label=""
			LEGACY_size="small"
			LEGACY_fallbackIcon={LegacyBillingIcon}
			color={token('color.icon.inverse')}
		/>
	</IconWrapper>
);

const getAdminSectionOld = (
	formatMessage: FormatMessage,
	appPermissions: ApplicationPermissions,
): NavigationItem => ({
	id: JIRA_SETTINGS_SECTION_IDS.SYSTEM_ADMIN,
	type: 'Group',
	hasSeparator: true,
	sideHeading: () => (
		<JiraCommandPaletteTipContainer
			aria-label={formatMessage(
				isMacOs()
					? messages.jiraSettingsCommandPaletteMacScreenReader
					: messages.jiraSettingsCommandPaletteOtherScreenReader,
			)}
		>
			{formatMessage(
				isMacOs()
					? messages.jiraSettingsCommandPaletteMac
					: messages.jiraSettingsCommandPaletteOther,
				{
					strong: (chunks: string) => (
						<Text as="strong" size="small" color="inherit">
							{chunks}
						</Text>
					),
					wrapper: (chunks: React.ReactNode[]) => (
						<CommandPaletteShortcutsContainer>{chunks}</CommandPaletteShortcutsContainer>
					),
					key: (chunks: string) => <KeyboardShortcut>{chunks}</KeyboardShortcut>,
					command: (chunks: string) => <KeyboardShortcut isMeta>{chunks}</KeyboardShortcut>,
				},
			)}
		</JiraCommandPaletteTipContainer>
	),
	items: [
		{
			goTo: 'root/global-settings/system', // sets active navigation 2nd level menu and an active item in navigation menu, via `navApi.setView(goTo)`
			id: ADMIN_SETTINGS_ITEM_IDS.SYSTEM,
			legacyId: 'admin_system_menu',
			href: '/jira/settings/system/general-configuration',
			text: formatMessage(messages.system),
			type: 'Item',
			before: renderScreenIcon,
		},
		{
			goTo: 'root/global-settings/applications',
			id: ADMIN_SETTINGS_ITEM_IDS.APPLICATIONS,
			legacyId: 'admin_applications_menu',
			href: getApplicationsMenuLink(appPermissions),
			text: formatMessage(messages.applications),
			type: 'Item',
			before: renderActivityIcon,
		},
		{
			goTo: 'root/global-settings/projects',
			id: ADMIN_SETTINGS_ITEM_IDS.MANAGE_PROJECTS,
			legacyId: 'admin_project_menu',
			href: '/jira/settings/projects/manage',
			text: formatMessage(messages.projects),
			type: 'Item',
			before: renderFolderIcon,
		},
		{
			goTo: 'root/global-settings/issues',
			id: ADMIN_SETTINGS_ITEM_IDS.ISSUES,
			legacyId: 'admin_issues_menu',
			href: '/jira/settings/issues/issue-types',
			text: formatMessage(
				fg('jira-issue-terminology-refresh-m3') ? messages.issuesIssueTermRefresh : messages.issues,
			),
			type: 'Item',
			before: renderIssuesIcon,
		},
		...(!isFedRamp()
			? [
					{
						goTo: 'root/global-settings/addons',
						id: ADMIN_SETTINGS_ITEM_IDS.PLUGINS,
						legacyId: 'admin_plugins_menu',
						href: getEmceeDiscoverLink({ source: 'side_nav_find_new_addons' }),
						text: formatMessage(messages.addons),
						type: 'Item',
						before: renderAddonIcon,
					},
				]
			: []),
	],
});

const getAdminSectionNew = (
	formatMessage: FormatMessage,
	appPermissions: ApplicationPermissions,
): NavigationItem => ({
	id: JIRA_SETTINGS_SECTION_IDS.SYSTEM_ADMIN,
	type: 'Group',
	hasSeparator: true,
	heading: formatMessage(messages.jiraAdminSettingsNonFinal),
	items: [
		{
			goTo: 'root/global-settings/system', // sets active navigation 2nd level menu and an active item in navigation menu, via `navApi.setView(goTo)`
			id: ADMIN_SETTINGS_ITEM_IDS.SYSTEM,
			legacyId: 'admin_system_menu',
			href: '/jira/settings/system/general-configuration',
			text: formatMessage(messages.system),
			type: 'Item',
			before: () => <ScreenIcon label="" color={token('color.icon')} />,
			description: formatMessage(messages.systemDescriptionNonFinal),
		},
		{
			goTo: 'root/global-settings/applications',
			id: ADMIN_SETTINGS_ITEM_IDS.APPLICATIONS,
			legacyId: 'admin_applications_menu',
			href: getApplicationsMenuLink(appPermissions),
			text: formatMessage(messages.applications),
			type: 'Item',
			before: () => <AppSwitcherIcon label="" color={token('color.icon')} />,
			description: formatMessage(messages.productsDescriptionNonFinal),
		},
		{
			goTo: 'root/global-settings/projects',
			id: ADMIN_SETTINGS_ITEM_IDS.MANAGE_PROJECTS,
			legacyId: 'admin_project_menu',
			href: '/jira/settings/projects/manage',
			text: formatMessage(messages.projects),
			type: 'Item',
			before: () => <ProjectIcon label="" color={token('color.icon')} />,
			description: formatMessage(messages.projectsDescriptionNonFinal),
		},
		{
			goTo: 'root/global-settings/issues',
			id: ADMIN_SETTINGS_ITEM_IDS.ISSUES,
			legacyId: 'admin_issues_menu',
			href: '/jira/settings/issues/issue-types',
			text: formatMessage(
				fg('jira-issue-terminology-refresh-m3') ? messages.issuesIssueTermRefresh : messages.issues,
			),
			type: 'Item',
			before: () => <IssuesIcon label="" color={token('color.icon')} />,
			description: formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.issuesDescriptionNonFinalIssueTermRefresh
					: messages.issuesDescriptionNonFinal,
			),
		},
		...(!isFedRamp()
			? [
					{
						goTo: 'root/global-settings/addons',
						id: ADMIN_SETTINGS_ITEM_IDS.PLUGINS,
						legacyId: 'admin_plugins_menu',
						href: manageAppsRoute.path,
						text: formatMessage(messages.addons),
						type: 'Item',
						before: () => <AppsIcon label="" color={token('color.icon')} />,
						description: formatMessage(messages.appsDescriptionNonFinal),
					},
				]
			: []),
		{
			id: 'keyboad-shortcut-tip',
			type: 'Content',
			content: () => (
				<Box
					as={fg('blu-4172-enghealth-13259-settings-a11y-fix') ? 'li' : 'div'}
					xcss={
						fg('blu-4172-enghealth-13259-settings-a11y-fix')
							? jiraCommandPalletteTipContainerStyles
							: oldJiraCommandPalletteTipContainerStyles
					}
					aria-label={formatMessage(
						isMacOs()
							? messages.jiraSettingsCommandPaletteMacScreenReader
							: messages.jiraSettingsCommandPaletteOtherScreenReader,
					)}
				>
					{formatMessage(
						isMacOs()
							? messages.jiraSettingsCommandPaletteMac
							: messages.jiraSettingsCommandPaletteOther,
						{
							strong: (chunks: string) => <Text size="small">{chunks}</Text>,
							wrapper: (chunks: React.ReactNode[]) => (
								<Box xcss={commandPalleteShortcutStyles}>{chunks}</Box>
							),
							key: (chunks: string) => (
								<Flex alignItems="center" justifyContent="center" xcss={keyboardShortcutStyles}>
									{chunks}
								</Flex>
							),
							command: (chunks: string) => (
								<Flex alignItems="center" justifyContent="center" xcss={keyboardShortcutStyles}>
									{chunks}
								</Flex>
							),
						},
					)}
				</Box>
			),
		},
	],
});

export const getSiteAdminSectionNew = (
	formatMessage: FormatMessage,
	isSiteAdmin: boolean,
	canManageUsers?: boolean,
	isHideBillingGlobalSettingForFedrampAdminsEnabled = false,
): NavigationItem => ({
	id: JIRA_SETTINGS_SECTION_IDS.SITE_ADMIN,
	heading: formatMessage(messages.atlassianAdminSettingsNonFinal),
	type: 'Group',
	hasSeparator: false,
	items: [
		...(canManageUsers || isSiteAdmin
			? [
					{
						id: SITE_ADMIN_SETTINGS_ITEM_IDS.USER_MANAGEMENT,
						legacyId: 'user_management_section',
						href: SITE_ADMIN_SETTINGS_ITEM_HREFS.USER_MANAGEMENT,
						text: formatMessage(messages.userManagement),
						type: 'Item',
						before: () => <TeamsIcon label="" color={token('color.icon')} />,
						after: () => <LinkExternalIcon label="" color={token('color.icon')} />,
						target: '_blank',
						description: formatMessage(messages.userManagementDescriptionNonFinal),
					},
				]
			: []),
		...(isSiteAdmin && !(isHideBillingGlobalSettingForFedrampAdminsEnabled && isFedRamp())
			? [
					{
						id: SITE_ADMIN_SETTINGS_ITEM_IDS.BILLING,
						legacyId: 'admin_billing_section',
						href: SITE_ADMIN_SETTINGS_ITEM_HREFS.BILLING,
						text: formatMessage(messages.billing),
						type: 'Item',
						before: () => <CreditCardIcon label="" color={token('color.icon')} />,
						after: () => <LinkExternalIcon label="" color={token('color.icon')} />,
						target: '_blank',
						description: formatMessage(messages.billingDescriptionNonFinal),
					},
				]
			: []),
	],
});
export const getSiteAdminSectionOld = (
	formatMessage: FormatMessage,
	isSiteAdmin: boolean,
	canManageUsers?: boolean,
	isHideBillingGlobalSettingForFedrampAdminsEnabled = false,
): NavigationItem => ({
	id: JIRA_SETTINGS_SECTION_IDS.SITE_ADMIN,
	type: 'Group',
	hasSeparator: false,
	items: [
		...(canManageUsers || isSiteAdmin
			? [
					{
						id: SITE_ADMIN_SETTINGS_ITEM_IDS.USER_MANAGEMENT,
						legacyId: 'user_management_section',
						href: SITE_ADMIN_SETTINGS_ITEM_HREFS.USER_MANAGEMENT,
						text: formatMessage(messages.userManagement),
						type: 'Item',
						before: renderPeopleGroupIcon,
						// @ts-expect-error - TS2769 - No overload matches this call.
						after: () => <LegacyShortcutIcon size="small" />,
						target: '_blank',
					},
				]
			: []),
		...(isSiteAdmin && !(isHideBillingGlobalSettingForFedrampAdminsEnabled && isFedRamp())
			? [
					{
						id: SITE_ADMIN_SETTINGS_ITEM_IDS.BILLING,
						legacyId: 'admin_billing_section',
						href: SITE_ADMIN_SETTINGS_ITEM_HREFS.BILLING,
						text: formatMessage(messages.billing),
						type: 'Item',
						before: renderBillingIcon,
						// @ts-expect-error - TS2769 - No overload matches this call.
						after: () => <LegacyShortcutIcon size="small" />,
						target: '_blank',
					},
				]
			: []),
	],
});

export const getOpsAdminSectionOld = (formatMessage: FormatMessage) => ({
	id: JIRA_SETTINGS_SECTION_IDS.OPS_ADMIN,
	type: 'Group',
	hasSeparator: false,
	items: [
		{
			goTo: 'root/global-settings/applications',
			id: ADMIN_SETTINGS_ITEM_IDS.APPLICATIONS,
			legacyId: 'admin_applications_menu',
			href: '/jira/settings/products/ops/role-assignment',
			text: formatMessage(messages.applications),
			type: 'Item',
			before: () => <AppSwitcherIcon label="" color={token('color.icon')} />,
			description: formatMessage(messages.productsDescriptionNonFinal),
		},
	],
});

type UserRoles = {
	isAdmin: boolean;
	isSiteAdmin: boolean;
	manageUsersPermission: {
		loading: boolean;
		canManageUsers: boolean | undefined;
	};
	isOpsAdmin?: boolean;
};

export const getGlobalSettingsAdminItems = (
	formatMessage: FormatMessage,
	appPermissions: ApplicationPermissions,
	userRoles: UserRoles,
	willShowNav4: boolean,
): NavigationItem[] => {
	const { isAdmin, isSiteAdmin, manageUsersPermission } = userRoles;
	const result: NavigationItem[] = [];

	if (isAdmin) {
		const getAdminSection = willShowNav4 ? getAdminSectionNew : getAdminSectionOld;

		result.push(getAdminSection(formatMessage, appPermissions));
	}

	// Adding these items to the end because `manageUsersPermission` may change after the initial
	// render because it is determined by an async API call. It will need to be the responsibility
	// of the consumer to show a loading state if needed.
	if (
		isSiteAdmin ||
		(manageUsersPermission.loading === false && manageUsersPermission.canManageUsers)
	) {
		const getSiteAdminSection = willShowNav4 ? getSiteAdminSectionNew : getSiteAdminSectionOld;

		result.push(
			getSiteAdminSection(
				formatMessage,
				userRoles.isSiteAdmin,
				manageUsersPermission.canManageUsers,
				// remove prop when cleaning up nin_hide_billing_global_setting_for_fedramp_admins
				fg('nin_hide_billing_global_setting_for_fedramp_admins'),
			),
		);
	}

	if (
		!willShowNav4 &&
		!userRoles.isAdmin &&
		userRoles.isOpsAdmin &&
		fg('operations-tenant-info-endpoint-update')
	) {
		result.push(getOpsAdminSectionOld(formatMessage));
	}

	return result;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconWrapper = styled.div<{ dark?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: (props) =>
		props.dark
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.background.neutral.bold', colors.N800)
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.background.brand.bold', colors.B200),
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '24px',
	width: '24px',
	borderRadius: '3px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommandPaletteShortcutsContainer = styled.div({
	display: 'inline-flex',
	gap: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const KeyboardShortcut = styled(FilledLozenge)<{ isMeta?: boolean }>({
	display: 'block',
	width: 'auto',
	minWidth: '14px',
	height: '14px',
	flexGrow: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
	lineHeight: '14px !important',
	textAlign: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	fontSize: ({ isMeta }) => (isMeta ? '11px !important' : '8px !important'),
	padding: `0 ${token('space.025', '2px')}`,
	boxSizing: 'border-box',
	textTransform: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const JiraCommandPaletteTipContainer = styled.div({
	fontWeight: 400,
	font: token('font.body.small'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.accent.gray', colors.N800),
	textTransform: 'none',
});

const commandPalleteShortcutStyles = xcss({
	display: 'inline-flex',
	gap: 'space.025',
});

// This can be removed when FG `blu-4172-enghealth-13259-settings-a11y-fix` is removed
const oldJiraCommandPalletteTipContainerStyles = xcss({
	font: token('font.body.small'),
	marginInlineStart: 'space.200',
	marginBlock: 'space.200',
	color: 'color.text.subtle',
});

const jiraCommandPalletteTipContainerStyles = xcss({
	font: token('font.body.small'),
	marginInlineStart: 'space.200',
	marginBlock: 'space.200',
	color: 'color.text.subtle',
	listStyleType: 'none',
});

const keyboardShortcutStyles = xcss({
	minWidth: '14px',
	height: '14px',
	backgroundColor: 'color.background.accent.blue.subtlest',
	color: 'color.text.subtle',
	padding: 'space.025',
	borderRadius: 'border.radius',
	font: token('font.body.small'),
});
